import React from 'react';
import { Link } from 'react-router-dom';

import Navigation from '../navigation/Navigation';

import { useStateValue } from '../../state/StateContext';

import style from './header.module.scss';

export default function Header() {
  const [{ isMenuVisible }, dispatch] = useStateValue();

  return (
    <header className={style.siteHeader}>
      <div className={style.headerContent}>
        <Link to="/">
          <span
            className={style.logo}
            onClick={() => dispatch({ type: 'cleanMenu' })}
          >
            ongradient.club
          </span>
        </Link>
        <div
          className={style.menuIcon}
          style={{ color: isMenuVisible ? '#f56857' : '#69707a' }}
          onClick={() => dispatch({ type: 'toggleMenu' })}
        ></div>
        <Navigation />
      </div>
    </header>
  );
}
