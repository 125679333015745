import React, { Suspense } from 'react';

import { Switch, Route } from 'react-router-dom';

import { useStateValue } from '../../state/StateContext';

import Welcome from '../welcome/Welcome';

import style from './content.module.scss';

import Loading from '../utils/loading/Loading';

const Curriculum = React.lazy(() => import('../curriculum/Curriculum'));

export default function Content() {
  const [{ isContentVisible }] = useStateValue();

  return (
    <div
      className={style.content}
      style={{ display: isContentVisible ? 'block' : 'none' }}
    >
      <div className={style.header}></div>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/curriculum/:subject/:level">
            <Curriculum />
          </Route>
          <Route path="/">
            <Welcome />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
}
