import React from 'react';
import { Link } from 'react-router-dom';

import { useStateValue } from '../../state/StateContext';

import style from './navigation.module.scss';

import sections from './navigation.json';

const MenuSection = ({ section, activeMenuItemId, dispatch }) => (
  <section className={style.subject}>
    <h1>{section.name}</h1>
    <ul>
      {section.items.map((item) => (
        <Link to={item.link} key={item.id}>
          <li
            className={item.id === activeMenuItemId ? style.active : null}
            onClick={() =>
              dispatch({ type: 'selectMenuItem', activeMenuItemId: item.id })
            }
          >
            {item.title}
          </li>
        </Link>
      ))}
    </ul>
  </section>
);

export default function Navigation() {
  const [{ isMenuVisible, activeMenuItemId }, dispatch] = useStateValue();

  return (
    <nav style={{ display: isMenuVisible ? 'block' : 'none' }}>
      {sections.map((section) => (
        <MenuSection
          key={section.id}
          section={section}
          activeMenuItemId={activeMenuItemId}
          dispatch={dispatch}
        />
      ))}
    </nav>
  );
}
