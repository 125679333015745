import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.module.scss';
import * as serviceWorker from './serviceWorker';

import { StateProvider } from './state/StateContext';

import Header from './components/header/Header';
import Content from './components/content/Content';
import Lesson from './components/lesson/Lesson';

const MOBILE_INTERFACE = Boolean(window.innerWidth < 1023);

const initialState = {
  isMenuVisible: !MOBILE_INTERFACE,
  isContentVisible: true,
  activeMenuItemId: null,
  activeLesson: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'addCourses':
      return {
        ...state,
        [action.subjectClassId]: action.courses,
      };

    case 'toggleMenu':
      return {
        ...state,
        isMenuVisible: !state.isMenuVisible,
        isContentVisible: !state.isContentVisible,
      };

    case 'selectMenuItem':
      return {
        ...state,
        activeMenuItemId: action.activeMenuItemId,
        isMenuVisible: MOBILE_INTERFACE
          ? !state.isMenuVisible
          : state.isMenuVisible,
        isContentVisible: MOBILE_INTERFACE
          ? !state.isContentVisible
          : state.isContentVisible,
      };

    case 'cleanMenu':
      return {
        ...state,
        activeMenuItemId: null,
        isMenuVisible: MOBILE_INTERFACE ? false : true,
      };

    case 'activateLesson':
      return {
        ...state,
        activeLesson: action.lesson,
      };

    default:
      return state;
  }
};

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <Router>
        <Header />
        <Content />
      </Router>
      <Lesson />
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
