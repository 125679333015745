import React from 'react';

import { useStateValue } from '../../state/StateContext';

import style from './lesson.module.scss';

export default function Lesson() {
  const [{ activeLesson }, dispatch] = useStateValue();

  return activeLesson ? (
    <div className={style.lesson}>
      <div className={style.title}>
        <div>{activeLesson.title}</div>
        <div
          className={style.close}
          onClick={() => dispatch({ type: 'activateLesson', lesson: null })}
        >
          ✕
        </div>
      </div>

      <div className={style.control}>
        <div className={(style.video, style.active)}>Урок</div>
        <a href={activeLesson.notes} target="_blank" rel="noopener noreferrer">
          <div className={style.notes}>Конспект</div>
        </a>
        <div className={style.question}>Тест</div>
      </div>

      <div className={style.materials}>
        <div className={style.ytv}>
          <iframe
            className={style.ytf}
            title={activeLesson.title}
            src={`https://www.youtube.com/embed/${activeLesson.video}?rel=0`}
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
            frameBorder="0"
          />
        </div>
      </div>
    </div>
  ) : null;
}
