import React from 'react';

import style from './welcome.module.scss';

export default function Welcome() {
  return (
    <section className={style.welcome}>
      <div className={style.logo}>&pi;</div>

      <div className={style.message}>
        <p>
          <strong>математична майстерня</strong>
        </p>
        <p>тетяни овчинникової</p>
      </div>

      <div className={style.message}>
        <p>вчимо розуміти математику</p>
        <p>методики апробовані часом</p>
      </div>
    </section>
  );
}
